import { ContactProps } from "./props/contact.props";
import "./contact.css"
import DOMPurify from "isomorphic-dompurify";

const allowedTags = {
    ALLOWED_TAGS: [
        'p',
        'strong',
        'i',
        'a',
        'ol',
        'li',
        'ul',
        'figure',
        'div',
        'oembed',
        'table',
        'tbody',
        'thead',
        'tr',
        'th',
        'td',
        'iframe',
        'br'
    ]
}
export default function Contact({ ...props }: Readonly<ContactProps>) {
    return (
        <div className="contact">
            <div className="mogu-contact" data-testid="contact">
                <div className="primary-info">
                    <div className="contact-title">
                        <h3>{props.Account}</h3>
                    </div>
                    <div className="logo-info">
                        <div className="product-logo">
                            <img src={props.Logo} alt="Logo" />
                        </div>
                        <div className="info">
                            <p>{props.Contact_type}</p>
                            <h4>{props.Fullname}</h4>
                        </div>
                    </div>
                </div>
                <div className="aditional-info">
                    <div className="location">
                        <i className="ri-map-pin-line"></i>
                        <p>{props.Address}</p>
                    </div>
                    <div className="web">
                        <i className="ri-link"></i>
                        <a href={props.Web}>{props.Web}</a>
                    </div>
                    <div className="email">
                        <i className="ri-mail-line"></i>
                        <p>{props.Email}</p>
                    </div>
                    <div className="phone">
                        <i className="ri-phone-line"></i>
                        <p>{props.Phone}</p>
                    </div>
                </div>
            </div>
            <div className="description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.Bio, allowedTags) }}>
            </div>
        </div>
    );
}
