import { forwardRef } from "react";
import { ContactProps } from "./props/contact.props";
import "./pdf-contact.css"

export default forwardRef<HTMLElement, ContactProps>(function Contact({ logoUrl }: Readonly<ContactProps>) {
  const imageStyle = {
    backgroundImage: `url(${logoUrl})`
  }

  return (
    <div className="header-image" style={imageStyle} />
  );
}
);