import ReactPDF from "@react-pdf/renderer";
import { IncludedServicesProps } from "../../../included-services/props/included-services.props";
import PdfText from "../pdf-text/pdf-text";
import style from "./pdf-included-services.style";

export default function PdfIncludedServices(
  props: Readonly<IncludedServicesProps>
) {
  const { Title, Text } = props;
  return (
    <ReactPDF.View>
      <ReactPDF.Text style={style.title}>{Title}</ReactPDF.Text>
      <ReactPDF.View style={style.productList}>
        <PdfText Text={Text} style={style.productList} />
      </ReactPDF.View>
    </ReactPDF.View>
  );
}
