import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  html: {
    fontSize: 10,
    color: "#152b5b",
  },
  bulletOverrides: {
    paddingRight: 4,
  },
  contentOverrides: {
    flexGrow: 1,
    flexBasis: 0,
  },
});

const stylesheet = {
  ["*"]: {
    color: "#1F2E5C",
  },
  a: {
    color: "#0000FF",
  },
  table: {
    border: "1px solid #e2e8f0",
  },
  [".table table tbody tr:nth-child(2n)"]: {
    backgroundColor: "#f8fafc",
  },
  td: {
    padding: "4px",
  },
  th: {
    padding: "4px",
  },
  [".table ul"]: {
    paddingLeft: "5px",
  },
  [".table li"]: {
    marginBottom: "3px",
  },
};

export { style, stylesheet };
