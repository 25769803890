import { Day, ItineraryProps } from "./props/itinerary.props";
import "./itinerary.css"
import ItineraryElement from './itinerary-element/itinerary-element';

export default function Itinerary(props: Readonly<ItineraryProps>) {
  return (
    <div data-testid="itinerary" className="itineraries-container">
      <div className="itineraries-box">
        {props?.Days?.map((x : Day) => <ItineraryElement key={x.id} day={x} />)}
      </div>
    </div>
  );
}
