import { forwardRef } from "react";
import { HeaderBasicsProps } from "./props/header-basics.props";
import "./header-basics.css";
import { buildDateWithDays } from "./utils/date-builder";

export default forwardRef<HTMLElement, HeaderBasicsProps>(
  function HeaderBasics({
    Title,
    StartDate,
    EndDate,
    NumDays,
    BrandUrl,
  }: Readonly<HeaderBasicsProps>) {
    return (
      <div data-testid="header-basics" className="header-basics-container">
        <div className="header-basics-grid">
          <div className="header-basics-box">
            <div className="header-basics-title-container">
              <div className="header-basics-title">{Title}</div>
              <div className="header-basics-subtitle">
                {buildDateWithDays(NumDays, StartDate, EndDate)}
              </div>
            </div>
            <img
              loading="lazy"
              className="header-basics-img"
              src={BrandUrl}
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
);
