import ReactPDF from "@react-pdf/renderer";
import PdfText from "../pdf-text/pdf-text";
import style from "./pdf-not-included-services.style";
import { NotIncludedServicesProps } from "../../../not-included-services/props/not-included-services.props";

export default function PdfNotIncludedServices(
  props: Readonly<NotIncludedServicesProps>
) {
  const { Title, Text } = props;
  return (
    <ReactPDF.View>
      <ReactPDF.Text style={style.title}>{Title}</ReactPDF.Text>
      <ReactPDF.View style={style.serviceList}>
        <PdfText Text={Text} style={style.serviceList} />
      </ReactPDF.View>
    </ReactPDF.View>
  );
}
