const buildDateWithDays = (
  numDays: number,
  startDateString: string,
  endDateString: string
): string => {
  const startDateFormatted = buildDate(startDateString);
  const endDateFormatted = buildDate(endDateString);

  if (startDateFormatted === "" || endDateFormatted === "") return "";

  return `${numDays} days | ${startDateFormatted} - ${endDateFormatted}`;
};

const isNullOrEmpty = (str: string) => str === null || str.trim().length === 0;

const formatDate = (date: Date) =>
  date
    .toLocaleDateString("es-ES", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(0, -3);

const buildDate = (dateString: string): string => {
  if (isNullOrEmpty(dateString)) return "";
  const date = new Date(dateString);
  if (isNaN(date.valueOf())) return "";
  return formatDate(date);
};

export { buildDateWithDays, buildDate };
