import { Text, View, Svg, Path, Image } from "@react-pdf/renderer";
import style from "./pdf-event.style";
import { EventProps } from "../../../event/props/event.props";
import PdfText from "../pdf-text/pdf-text";
import icons from "./icons/icons";
import PdfGoogleWidget from "../pdf-google-widget/pdf-google-widget";
import PdfFlight from "../pdf-flight/pdf-flight";
import ImageUrlProxyUtils from "../../../../../../utils/imageUrlProxy.utils";

export default function PdfEvent(props: Readonly<EventProps>) {
  const {
    address,
    flight,
    description,
    icon,
    googleRatingsLiteral,
    internationalPhone,
    place,
    rating,
    ship,
    title,
    totalRatings,
    url,
    website,
    optional,
    imageUrls,
  } = props;

  const hasGooglePlace = (): boolean =>
    address != null ||
    internationalPhone != null ||
    rating != null ||
    url != null ||
    totalRatings != null ||
    website != null;

  const iconPath = icons[icon];
  return (
    <View style={style.container}>
      <View style={style.titleContainer}>
        {iconPath && (
          <Svg style={style.iconTitle} viewBox="0 0 24 24">
            <Path fill="#667b99" d={iconPath} />
          </Svg>
        )}
        <Text>
          {optional ? "(Optional) " : null}
          {title}
        </Text>
      </View>

      {ship && (
        <View style={style.shipContainer}>
          <Text>{ship.operatedByLiteral}</Text>
          <Text>
            {ship.shipLiteral} {ship.name}
          </Text>
        </View>
      )}

      {ship && <Image style={style.shipImage} src={ImageUrlProxyUtils.processImageUrl(ship.image)} />}
      {!hasGooglePlace() && !ship && imageUrls?.length > 0 && <Image style={style.shipImage} src={ImageUrlProxyUtils.processImageUrl(imageUrls[0])} />}

      {description && <PdfText Text={description} />}

      {hasGooglePlace() && (
        <PdfGoogleWidget
          formatted_address={address}
          international_phone_number={internationalPhone}
          name={place}
          photos={imageUrls?.map(url => ImageUrlProxyUtils.processImageUrl(url))}
          rating={rating}
          url={url}
          user_ratings_total={totalRatings}
          website={website}
          ratingsLiteral={googleRatingsLiteral}
        />
      )}

      {flight && flight != undefined && (
        <PdfFlight
          airlineLogo={props.flight!.airlineLogo}
          airlineName={props.flight!.airlineName}
          arrival={props.flight!.arrival}
          departure={props.flight!.departure}
          flightNumber={props.flight!.flightNumber}
          flightTime={props.flight!.flightTime}
        />
      )}
    </View>
  );
}
