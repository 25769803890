import { StyleSheet } from "@react-pdf/renderer";

const style = StyleSheet.create({
  container: {
    marginVertical: 8
  },
  text: {
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: '#475569',
    fontWeight: 'bold',
    fontSize: '10px'
  }
});

export default style;
