import { View, Text } from "@react-pdf/renderer";
import style from "./pdf-price.style";
import PdfText from "../pdf-text/pdf-text";
import { PdfPriceProps } from "./props/pdf-price.props";

export default function PdfPrice({
  amount,
  description,
  title,
  tertiaryColor500,
}: Readonly<PdfPriceProps>) {
  return (
    <View style={style.container}>
      <View style={style.titleContainer}>
        <Text style={style.title}>{title}</Text>
        <Text style={{ color: tertiaryColor500 ?? "" }}>{amount}€</Text>
      </View>
      {description && <PdfText Text={description} />}
    </View>
  );
}
