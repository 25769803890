import Html from "react-pdf-html";
import { TextProps } from "../../../text/props/text.props";
import { style, stylesheet } from "./pdf-text.style";
import {
  renderCustomFigure,
  renderCustomLi,
} from "./pdf-text-custom-renderers";

export default function PdfText(props: Readonly<TextProps>) {
  const text = props.Text;

  return (
    <Html
      style={style.html}
      renderers={{
        figure: renderCustomFigure,
        li: renderCustomLi(style.bulletOverrides, style.contentOverrides),
      }}
      stylesheet={stylesheet}
    >
      {text}
    </Html>
  );
}