import { Text, View } from "@react-pdf/renderer";
import style from "./pdf-box.style";
import { BoxProps } from "../../../box/props/box.props";
import PdfText from "../pdf-text/pdf-text";

export default function PdfBox(props: Readonly<BoxProps>) {
  const { Color, Title } = props;
  const text = props.Text;
  return (
    <View wrap={false} style={{ ...style.container, backgroundColor: Color + BoxProps.MoguTransparencyHEX}}>
      <Text style={style.title}>{Title}</Text>
      <PdfText Text={text} />
    </View>
  );
}
