import { lazy } from "react";
import Box from "../components/box/box";
import Button from "../components/button/button";
import Card from "../components/card/card";
import Text from "../components/text/Text";
import TextImage from "../components/text_image/TextImage";
import IconsGrid from "../components/icons-grid/icons-grid";
import Footer from "../components/footer/footer";
import Product from "../components/product/product";
import OwnProductCarousel from "../components/own-product-carousel/own-product-carousel";

const desktopComponents = {
  agency: lazy(() => import("../components/alexandria/desktop/agency/agency")),
  "banner-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/desktop/banner-traveltool/banner-traveltool"
      )
  ),
  box: Box,
  button: Button,
  card: Card,
  "own-product-carousel": OwnProductCarousel,
  "card-destination-carousel": lazy(
    () =>
      import(
        "../components/alexandria/desktop/card-destination-carousel/card-destination-carousel"
      )
  ),
  "carousel-tabs": lazy(
    () => import("../components/alexandria/desktop/carousel-tabs/carousel-tabs")
  ),
  footer: Footer,
  "footer-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/desktop/footer-traveltool/footer-traveltool"
      )
  ),
  "features-list-traveltool": lazy(
    () =>
      import(
        "../components/alexandria/desktop/features-list-traveltool/features-list-traveltool"
      )
  ),
  "featured-cards": lazy(
    () =>
      import("../components/alexandria/desktop/featured-cards/featured-cards")
  ),
  header: lazy(() => import("../components/header/header")),
  "header-banner": lazy(
    () => import("../components/header-banner/header-banner")
  ),
  "header-carousel": lazy(
    () =>
      import("../components/alexandria/desktop/header-carousel/header-carousel")
  ),
  "header-traveltool": lazy(() => import("../components/header/header")),
  multisearcher: lazy(
    () => import("../components/alexandria/desktop/multisearcher/multisearcher")
  ),
  "newsletter-subscription-ttool": lazy(
    () =>
      import(
        "../components/alexandria/desktop/newsletter-subscription/newsletter-subscription"
      )
  ),
  product: Product,
  "icons-grid": IconsGrid,
  "product-cards-carousel": lazy(
    () =>
      import(
        "../components/alexandria/desktop/product-cards-carousel/product-cards-carousel"
      )
  ),
  row: Box,
  text: Text,
  "text-image": TextImage,
};

export default class ComponentMapper {
  static getMappedComponent(componentName: string, environment: string) {
    if (componentName === "header-traveltool" && environment === "production") {
      const component = lazy(
        () =>
          import(
            "../components/alexandria/desktop/header-traveltool/header-traveltool"
          )
      );
      return component;
    }
    return desktopComponents[componentName];
  }
}
