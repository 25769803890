import { View } from "@react-pdf/renderer";
import PdfPrice from "../pdf-price/pdf-price";
import { PdfPricesProps } from "./props/pdf-prices.props";

export default function PdfPrices({
  items,
  tertiaryColor500,
}: Readonly<PdfPricesProps>) {
  return (
    <View>
      {items.map((x) => (
        <PdfPrice
          key={`${x.amount}-${x.title}`}
          amount={x.amount}
          description={x.description}
          title={x.title}
          tertiaryColor500={tertiaryColor500}
        />
      ))}
    </View>
  );
}
