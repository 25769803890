import { Text, View } from "@react-pdf/renderer";
import style from "./pdf-section.style";
import { PdfSectionProps } from "./props/pdf-section.props";

export default function PdfSection({ title }: Readonly<PdfSectionProps>) {
  return (
    <View style={style.container}>
      <Text style={style.text}>{title}</Text>
    </View>
  );
}
